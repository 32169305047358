import {
  AccessLevel,
  APIContact,
  Category,
  ContactGroup,
  createContactReference,
  SearchableTask,
  TaskStatus,
  TaskStatusChangeAction,
  Workspace,
} from '@super-software-inc/foundation'
import {
  changeTaskStatus,
  getTasksFromTypesense,
  GetTasksFromTypesenseProps,
} from 'api/tasks'
import placeholder from 'assets/images/loading-placeholder.gif'
import { noPropertySelectedAtom } from 'components/app/SidebarNav/AssociationDropdown'
import TaskViewOptions, {
  DateFilterOption,
  FilterInterface,
  FilterType,
  MyTasksOption,
  SortOption,
} from 'components/app/TaskViewOptions'
import { FlexRow, TruncatedText } from 'components/lib'
import { VirtualizedSortableTableHeader } from 'components/lib/NewTable'
import { addDays, startOfYear } from 'date-fns'
import { isEqual } from 'lodash'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { MdOutlineCircle } from 'react-icons/md'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { usePrevious } from 'react-use'
import { FixedSizeList as List } from 'react-window'
import InfiniteLoader from 'react-window-infinite-loader'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import {
  authenticatedUserAtom,
  searchableTasksAtom,
  showTaskFormAtom,
  taskFilterAtom,
  tasksCountAtom,
} from 'state/atoms'
import styled from 'styled-components/macro'
import closeAllSubtasks from 'utils/closeAllSubtasks'
import LoadingIcon from 'components/lib/LoadingIcon'
import { StatusSelectorOnChange } from 'components/app/StatusSelector'
import {
  selectedAssociationChoicesIdsSelector,
  windowDimensionsAtom,
} from '../../AppRoutes'
import { companyTaskCategoriesAtom, selectedTaskAtom } from './index'
import TaskRow from './TaskRow'

export const TasksContent = styled.div<{ noTasksAvailable?: boolean }>`
  padding: 0px 20px;
  overflow-y: ${noTasksAvailable => (noTasksAvailable ? 'visible' : 'scroll')};
  overflow-x: ${noTasksAvailable => (noTasksAvailable ? 'visible' : 'hidden')};

  display: flex;
  flex-direction: column;

  @media only screen and (max-width: 1350px) {
    padding-right: 0;
  }

  @media only screen and (max-width: 600px) {
    p:first-of-type {
      margin-left: 5px;
    }
    padding: 0;
  }
`

export const SectionHeader = styled.div`
  height: 60px;
  font-size: 12px;
  line-height: 18px;
  font-weight: 600;
  position: relative;
`

export const ViewOptionsContainer = styled.div`
  display: flex;
  position: fixed;
  right: 20px;
  top: 56px;
  z-index: 200;

  @media only screen and (max-width: 767px) {
    position: absolute;
    top: 110px;
  }
`

const TaskListTableHeader = ({
  sortKey,
  sortOrder,
  handleSort,
  totalTasks,
  workspace,
  titleColumnWidth,
  className,
  style,
}: {
  style: React.CSSProperties
  sortKey: SortOption
  sortOrder: 'asc' | 'desc'
  handleSort: Function
  totalTasks: number
  workspace?: string | null
  titleColumnWidth: number
  className?: string
}) => (
  <div
    style={{
      ...style,
      display: 'flex',
      width: 'fit-content',
      borderBottom: '1px solid #E5E5E5',
      borderTop: '1px solid #E5E5E5',
    }}
  >
    <VirtualizedSortableTableHeader
      className={className}
      active={sortKey === SortOption.Status}
      sortOrder={sortOrder}
      onClick={() => handleSort(SortOption.Status)}
      leftHandIndicator
      style={{
        paddingLeft: 65,
        minWidth: 100,
        width: 100,
        display: 'flex',
        justifyContent: 'flex-end',
        paddingRight: 0,
      }}
    >
      <MdOutlineCircle
        style={{
          display: 'block',
        }}
        color="#9daabf"
        size={20}
      />
    </VirtualizedSortableTableHeader>
    <VirtualizedSortableTableHeader
      className={className}
      active={sortKey === SortOption.Title}
      sortOrder={sortOrder}
      onClick={() => handleSort(SortOption.Title)}
      style={{
        textAlign: 'left',
        width: titleColumnWidth,
        minWidth: titleColumnWidth,
        paddingLeft: 14,
      }}
    >
      {`TASKS (${totalTasks})`}
    </VirtualizedSortableTableHeader>

    <VirtualizedSortableTableHeader
      active={sortKey === SortOption.Locations}
      sortOrder={sortOrder}
      onClick={() => handleSort(SortOption.Locations)}
      style={{
        textAlign: 'left',
        width: 160,
        minWidth: 160,
      }}
    >
      Location
    </VirtualizedSortableTableHeader>
    <VirtualizedSortableTableHeader
      active={
        sortKey === SortOption.UpdatedAt || sortKey === SortOption.Default
      }
      sortOrder={sortOrder}
      onClick={() => handleSort(SortOption.UpdatedAt)}
      style={{ textAlign: 'left', width: 90, minWidth: 90 }}
    >
      UPDATED
    </VirtualizedSortableTableHeader>

    <VirtualizedSortableTableHeader
      active={sortKey === SortOption.ModifiedBy}
      sortOrder={sortOrder}
      onClick={() => handleSort(SortOption.ModifiedBy)}
      style={{ textAlign: 'left', width: 150, minWidth: 150 }}
    >
      <TruncatedText>UPDATED BY</TruncatedText>
    </VirtualizedSortableTableHeader>

    {!workspace && (
      <VirtualizedSortableTableHeader
        active={sortKey === SortOption.Workspaces}
        sortOrder={sortOrder}
        onClick={() => handleSort(SortOption.Workspaces)}
        style={{ textAlign: 'left', width: 130, minWidth: 130 }}
      >
        WORKSPACE
      </VirtualizedSortableTableHeader>
    )}
    <VirtualizedSortableTableHeader
      active={sortKey === SortOption.Category}
      sortOrder={sortOrder}
      onClick={() => handleSort(SortOption.Category)}
      style={{ textAlign: 'left', width: 120, minWidth: 120 }}
    >
      CATEGORIES
    </VirtualizedSortableTableHeader>
    <VirtualizedSortableTableHeader
      active={sortKey === SortOption.DueDate}
      sortOrder={sortOrder}
      onClick={() => handleSort(SortOption.DueDate)}
      style={{ textAlign: 'left', width: 108, minWidth: 108 }}
    >
      DUE DATE
    </VirtualizedSortableTableHeader>

    <VirtualizedSortableTableHeader
      active={sortKey === SortOption.Assignee}
      sortOrder={sortOrder}
      onClick={() => handleSort(SortOption.Assignee)}
      style={{ textAlign: 'left', width: 32, minWidth: 32 }}
    />
  </div>
)

const TaskListRenderer = ({
  index,
  data,
  style,
}: {
  index: number
  data: {
    data: SearchableTask[]
    className: string
    selectedTask: SearchableTask | undefined
    taskDrawerIsOpen: boolean
    setTaskStatus: StatusSelectorOnChange
    navigateToTask: (task: SearchableTask) => void
    // table header props
    sortKey?: SortOption
    sortOrder?: 'asc' | 'desc'
    handleSort?: Function
    totalTasks: number
    associations?: string[]
    titleColumnWidth: number
    workspace?: string | null
  }
  style: React.CSSProperties
}) => {
  const {
    className,
    selectedTask,
    taskDrawerIsOpen,
    setTaskStatus,
    navigateToTask,
    sortKey,
    sortOrder,
    handleSort,
    totalTasks,
    titleColumnWidth,
    workspace,
  } = data
  const task = data.data[index]

  if (index === 0) {
    return (
      <TaskListTableHeader
        style={{ ...style, width: 'unset' }}
        sortKey={sortKey || SortOption.Default}
        sortOrder={sortOrder || 'asc'}
        handleSort={handleSort || (() => {})}
        totalTasks={totalTasks || 0}
        titleColumnWidth={titleColumnWidth}
        className={className}
        workspace={workspace}
      />
    )
  }

  if (!task || 'id' in task === false) {
    return (
      <FlexRow
        align="center"
        justify="center"
        style={{ ...style, height: 50 }}
        className="w-full"
      >
        <LoadingIcon />
      </FlexRow>
    )
  }

  return (
    <TaskRow
      key={task.id}
      titleColumnWidth={titleColumnWidth}
      style={{ ...style, width: '100%' }}
      className={className}
      highlight={selectedTask?.id === task.id && taskDrawerIsOpen}
      task={task}
      onClick={async () => {
        navigateToTask(task)
      }}
      onStatusChange={setTaskStatus}
    />
  )
}

const calculateDateFilter = (filter: FilterInterface) => {
  const now = new Date()
  const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
  const nextWeek = addDays(today, 7)
  const nextMonth = addDays(today, 30)

  let dueDateBefore: number | undefined
  let dueDateAfter: number | undefined
  let hasDueDate: boolean | undefined

  if (filter.value === DateFilterOption.Overdue) {
    dueDateBefore = today.valueOf()
  } else if (filter.value === DateFilterOption.Next7Days) {
    dueDateAfter = today.valueOf()
    dueDateBefore = nextWeek.valueOf()
  } else if (filter.value === DateFilterOption.Next14Days) {
    dueDateAfter = today.valueOf()
    dueDateBefore = addDays(today, 14).valueOf()
  } else if (filter.value === DateFilterOption.Next30Days) {
    dueDateAfter = today.valueOf()
    dueDateBefore = nextMonth.valueOf()
  } else if (filter.value === DateFilterOption.Last30Days) {
    dueDateAfter = addDays(today, -30).valueOf()
    dueDateBefore = today.valueOf()
  } else if (filter.value === DateFilterOption.ThisYear) {
    dueDateAfter = startOfYear(today).valueOf()
  } else if (filter.value === DateFilterOption.NoDueDate) {
    // No due date
    hasDueDate = false
  } else if (filter.value === DateFilterOption.MonthToDate) {
    dueDateAfter = new Date(now.getFullYear(), now.getMonth(), 1).valueOf()
    dueDateBefore = today.valueOf()
  }

  return { dueDateBefore, dueDateAfter, hasDueDate }
}

const calculateTypesenseSortString = (
  sortKey: SortOption,
  sortOrder: string,
  selectedContact: APIContact,
) => {
  let order = sortOrder // by default
  let sortByStr = ''

  if (sortKey === SortOption.Default || sortKey === SortOption.UnreadFirst) {
    // If default view or unread, reset the order to descending:
    order = 'desc'
  }

  if (sortKey === SortOption.UnreadFirst) {
    sortByStr = `_eval(read:!=${selectedContact.id}):desc`
  } else if (sortKey === SortOption.DueDate) {
    sortByStr = `dueDateValue:${order}`
  } else if (sortKey === SortOption.Title) {
    sortByStr = `title:${order}`
  } else if (sortKey === SortOption.ModifiedBy) {
    sortByStr = `modifiedBy.firstName:${order},modifiedBy.lastName:${order}`
  } else if (sortKey === SortOption.Locations) {
    // If there is more than one association selected, sort by association name first, then location name:
    sortByStr = `associationName:${order},locationsString:${order}`
  } else if (sortKey === SortOption.Property) {
    sortByStr = `associationName:${order}`
  } else if (sortKey === SortOption.Category) {
    sortByStr = `taskCategoriesString:${order}`
  } else if (sortKey === SortOption.Workspaces) {
    sortByStr = `workspace:${order}`
  } else if (sortKey === SortOption.CreatedBy) {
    sortByStr = `createdBy.firstName:${order},createdBy.lastName:${order}`
  } else if (sortKey === SortOption.Assignee) {
    sortByStr = `assignee.firstName:${order},assignee.lastName:${order}`
  } else if (sortKey === SortOption.Status) {
    sortByStr = `status:${order}`
  } else if (
    sortKey === SortOption.UpdatedAt ||
    sortKey === SortOption.Default
  ) {
    sortByStr = `updatedAt:${order},createdAt:${order}`
  }

  return sortByStr
}

const OPEN_TASK_STATUSES = [
  TaskStatus.OPEN,
  TaskStatus.PENDING,
  TaskStatus.ON_HOLD,
]
const CLOSED_TASK_STATUSES = [TaskStatus.CLOSED, TaskStatus.CANCELLED]
const ALL_TASK_STATUSES = [...OPEN_TASK_STATUSES, ...CLOSED_TASK_STATUSES]

const calculateTypesenseFilterProps = (
  filter: FilterInterface,
  noPropertySelected: boolean,
  associationIds: string[],
  showCompleted: boolean,
  companyTaskCategories: Category[],
  workspace: string | null,
  sortKey: SortOption,
  sortOrder: 'asc' | 'desc',
  selectedContact: APIContact,
) => {
  const props: GetTasksFromTypesenseProps = {
    associationIds: noPropertySelected
      ? [...associationIds, 'null']
      : associationIds,
    status: showCompleted ? ALL_TASK_STATUSES : OPEN_TASK_STATUSES,
    // If workspace is null, don't filter by workspaces. If workspace is 'null'
    // (string, from URL params), don't add filter param here but use the
    // `isWorkspaceTask` filter below
    workspaces:
      workspace === null || workspace === 'null'
        ? []
        : [workspace as Workspace],
  }

  if (workspace === 'null') {
    props.isWorkspaceTask = false
  }

  if (filter.type === FilterType.MyTasks) {
    if (filter.value === MyTasksOption.Created) {
      props.createdByContactIds = [selectedContact.id]
    }
    if (filter.value === MyTasksOption.Assigned) {
      props.assignedToContactIds = [selectedContact.id]
    }
    if (filter.value === MyTasksOption.Subscribed) {
      props.subscribedContactIds = [selectedContact.id]
    }
  } else if (filter.type === FilterType.Assignee) {
    if (filter.value === 'unassigned') {
      props.assignedToContactIds = ['null']
    } else if (filter.value) {
      props.assignedToContactIds = [filter.value]
    }
  } else if (filter.type === FilterType.CreatedBy && filter.value) {
    props.createdByContactIds = [filter.value]
  } else if (filter.type === FilterType.Subscriber && filter.value) {
    props.subscribedContactIds = [filter.value]
  } else if (filter.type === FilterType.Status && filter.value?.length) {
    props.status = [filter.value as TaskStatus]
  } else if (filter.type === FilterType.Category) {
    // Convert the category names to their ids:
    props.taskCategories = companyTaskCategories
      .filter(c => filter.value.includes(c.name))
      .map(c => c.id)
  } else if (filter.type === FilterType.Date) {
    const { dueDateBefore, dueDateAfter, hasDueDate } =
      calculateDateFilter(filter)

    props.dueDateValueBefore = dueDateBefore?.toString()
    props.dueDateValueAfter = dueDateAfter?.toString()
    props.hasDueDate = hasDueDate
  } else if (filter.type === FilterType.Recurring) {
    props.isRecurring = true
  } else if (filter.type === FilterType.Urgent) {
    props.isUrgent = true
  } else if (filter.type === FilterType.CreatedDate) {
    if (filter.value === DateFilterOption.MonthToDate) {
      props.dueDateValueAfter = new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        1,
      )
        .valueOf()
        .toString()
    }
  } else if (filter.type === FilterType.ClosedDate) {
    if (filter.value === DateFilterOption.MonthToDate) {
      props.status = [TaskStatus.CLOSED] // override the status
      props.dueDateValueAfter = new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        1,
      )
        .valueOf()
        .toString()
    } else if (filter.value === DateFilterOption.Last30Days) {
      props.status = [TaskStatus.CLOSED]
      props.dueDateValueAfter = new Date(
        new Date().setDate(new Date().getDate() - 30),
      )
        .valueOf()
        .toString()
    }
  }

  // If the user has full access or admin access, hide subtasks. If not, subtasks will be
  // shown in the listview:
  if (
    selectedContact.propertyInfo.every(
      p =>
        p.accessLevel === AccessLevel.FullAccess ||
        p.accessLevel === AccessLevel.AdminAccess,
    )
  ) {
    props.isSubTask = false
  }

  // Set the sort string:
  props.sortByStr = calculateTypesenseSortString(
    sortKey,
    sortOrder,
    selectedContact,
  )

  return props
}

interface VirtualizedTaskListViewProps {
  contacts: APIContact[]
  onRequestSheetClose: (
    e?: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => void
}

const VirtualizedTaskListView = ({
  onRequestSheetClose,
  contacts,
}: VirtualizedTaskListViewProps) => {
  const navigate = useNavigate()
  const location = useLocation()
  const authenticatedUser = useRecoilValue(authenticatedUserAtom)
  const { selectedCompany } = useRecoilValue(authenticatedUserAtom)

  const [searchParams] = useSearchParams()
  const workspace = searchParams.get('workspace')
  const [loadingNextPage, setLoadingNextPage] = useState(false)

  const associationIds = useRecoilValue(selectedAssociationChoicesIdsSelector)
  const noPropertySelected = useRecoilValue(noPropertySelectedAtom)
  const setShowTaskForm = useSetRecoilState(showTaskFormAtom)
  const [windowDimensions] = useRecoilState(windowDimensionsAtom)
  const companyTaskCategories = useRecoilValue(companyTaskCategoriesAtom)
  const [className] = useState('anchorTableCell')
  const [selectedTask, setSelectedTask] = useRecoilState(selectedTaskAtom)
  const [tasksCount, setTasksCount] = useRecoilState(tasksCountAtom)
  const [tasksPage, setTasksPage] = useState(1)

  const contactSecrets = authenticatedUser.secrets?.find(
    secret => secret.companyId === selectedCompany.id,
  )

  const [filter, setFilter] = useRecoilState(taskFilterAtom)

  const taskDrawerIsOpen = location.pathname.includes('/tasks/')

  const [sortKey, setSortKey] = useState(
    workspace === Workspace.WorkOrders
      ? SortOption.DueDate
      : SortOption.Default,
  )
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('desc')
  const [showCompleted, setShowCompleted] = useState(false)

  const handleSort = (nextSort: SortOption) => {
    if (nextSort === sortKey) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc')
    } else {
      setSortKey(nextSort)
      setSortOrder('asc')
    }
  }

  const [searchableTasks, setSearchableTasks] =
    useRecoilState(searchableTasksAtom)

  const [loading, setLoading] = useState<boolean>(false)

  // Pipe back changes from the sheet directly to the list:
  useEffect(() => {
    if (selectedTask) {
      const taskIdx = searchableTasks.findIndex(t => t.id === selectedTask.id)
      const updatedTasks = [...searchableTasks]

      if (taskIdx !== -1) {
        updatedTasks[taskIdx] = selectedTask
        setSearchableTasks(updatedTasks)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTask])

  // Calculate the filter options for the API call to Typesense:
  const apiFilterOptions = useMemo(
    (): GetTasksFromTypesenseProps =>
      calculateTypesenseFilterProps(
        filter,
        noPropertySelected,
        associationIds,
        showCompleted,
        companyTaskCategories,
        workspace,
        sortKey,
        sortOrder,
        authenticatedUser.selectedContact,
      ),
    [
      noPropertySelected,
      associationIds,
      showCompleted,
      filter,
      sortKey,
      sortOrder,
      authenticatedUser.selectedContact,
      companyTaskCategories,
      workspace,
    ],
  )

  const includeInternalComments = useMemo(
    () =>
      authenticatedUser.selectedContact.propertyInfo?.some(
        p =>
          (associationIds.includes(p.associationId) &&
            p?.groups.some(
              g => g === ContactGroup.Staff || g === ContactGroup.Management,
            )) ||
          false,
      ),
    [associationIds, authenticatedUser.selectedContact.propertyInfo],
  )

  const loadTasks = ({
    page,
    showLoading,
  }: {
    page: number
    showLoading?: boolean
  }) => {
    if (showLoading) {
      setLoading(true)
    }

    const params: GetTasksFromTypesenseProps = {
      ...apiFilterOptions,
      includeInternalComments,
      page,
    }

    getTasksFromTypesense(params, contactSecrets).then(res => {
      setTasksCount(res.totalTasks)
      setSearchableTasks(res.tasks)
      setLoading(false)
    })
  }

  const prevAssociationIds = usePrevious(associationIds)
  const prevContactSecrets = usePrevious(contactSecrets)
  const prevNoPropertySelected = usePrevious(noPropertySelected)
  const prevApiFilterOptions = usePrevious(apiFilterOptions)

  // This effect triggers when the contact secrets come in, and loads the
  // initial batch of tasks:
  useEffect(() => {
    if (!prevContactSecrets && contactSecrets) {
      loadTasks({ page: 1, showLoading: true })
    }
    // Exclude loadTasks from the dependency array
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prevContactSecrets, contactSecrets])

  // This effect triggers when the associations or filters change, and reloads
  // the tasks:
  useEffect(() => {
    if (!contactSecrets) {
      return
    }

    // If the associations change, reset the task list and page param:
    if (
      (prevAssociationIds && !isEqual(prevAssociationIds, associationIds)) ||
      (prevNoPropertySelected !== undefined &&
        prevNoPropertySelected !== noPropertySelected)
    ) {
      setSearchableTasks([])
      setTasksPage(1)

      loadTasks({ page: 1, showLoading: true })
    } else if (
      prevApiFilterOptions &&
      !isEqual(prevApiFilterOptions, apiFilterOptions)
    ) {
      setSearchableTasks([])
      setTasksPage(1)

      loadTasks({ page: 1, showLoading: true })
    }
    // Exclude loadTasks from the dependency array
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    prevAssociationIds,
    associationIds,
    prevNoPropertySelected,
    noPropertySelected,
    contactSecrets,
    prevApiFilterOptions,
    apiFilterOptions,
  ])

  async function getTaskPage(page: number) {
    // If we don't have the contactSecrets yet (aka the heartbeat hasn't come in yet), don't get tasks:
    if (!contactSecrets) {
      return []
    }

    setLoadingNextPage(true)

    const params: GetTasksFromTypesenseProps = {
      ...apiFilterOptions,
      includeInternalComments,
      page,
    }

    const result = await getTasksFromTypesense(params, contactSecrets)

    setTasksCount(result.totalTasks)
    setLoadingNextPage(false)

    return result.tasks
  }

  const navigateToTask = (task: SearchableTask) => {
    if (!task.read.includes(authenticatedUser.selectedContact.id)) {
      // If the task was not previously read, mark it as read locally. The tasksheet
      // will deal with updating the read status remotely:
      const taskWithUpdatedReadStatus = {
        ...task,
        read: [...task.read, authenticatedUser.selectedContact.id],
      }
      const taskIdx = searchableTasks.findIndex(t => t.id === task.id)
      const updatedTasks = [...searchableTasks]
      if (taskIdx !== -1) {
        updatedTasks[taskIdx] = taskWithUpdatedReadStatus
        setSearchableTasks(updatedTasks)
      }
    }

    navigate(`/tasks/${task.id}${workspace ? `?workspace=${workspace}` : ''}`)
  }

  const setTaskStatus = async ({
    task,
    newStatus,
    action,
  }: {
    task: SearchableTask
    newStatus: TaskStatus
    action: TaskStatusChangeAction
  }) => {
    // Change task status locally immediately:
    const taskIdx = searchableTasks.findIndex(t => t.id === task.id)
    const changedTask = { ...task, status: newStatus }
    const updatedTasks = [...searchableTasks]

    if (taskIdx !== -1) {
      updatedTasks[taskIdx] = changedTask
      setSearchableTasks(updatedTasks)
    }

    const contactReference = createContactReference(
      authenticatedUser.selectedContact,
    )

    await changeTaskStatus({
      companyId: authenticatedUser.selectedCompany.id,
      task,
      newStatus,
      contact: authenticatedUser.selectedContact,
      action,
    })

    if (selectedTask?.id === task.id) {
      setSelectedTask({ ...task, status: newStatus })
    }

    if (newStatus === 'closed') {
      setSearchableTasks(searchableTasks.filter(apitask => apitask !== task))
      closeAllSubtasks(
        authenticatedUser.selectedCompany.id,
        task.id,
        contactReference,
      )
    }
  }

  useEffect(() => {
    const subscribed = searchParams.get('subscribed') as string
    const summary = searchParams.get('summary') as string

    if (subscribed) {
      setFilter({ type: FilterType.Subscriber, value: subscribed })
      setShowCompleted(true)
    } else if (summary) {
      setFilter({ type: FilterType.Date, value: DateFilterOption.Next30Days })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams, filter.type])

  const handleResetViewOptions = () => {
    setSortKey(SortOption.Default)
    setFilter({ type: undefined, value: '' })
    setShowCompleted(false)
  }

  const [spinnerTimeout, setSpinnerTimeout] = useState(false)
  const spinnerTimer = useRef<any>(null)

  useEffect(() => {
    clearTimeout(spinnerTimer.current)
    if (loading) {
      setSpinnerTimeout(false)

      spinnerTimer.current = setTimeout(() => {
        setSpinnerTimeout(true)
      }, 1500)
    }
    return () => {
      clearTimeout(spinnerTimer.current)
    }
  }, [loading, spinnerTimer])

  const titleColumnWidth = useMemo(
    () =>
      windowDimensions.width > 1350
        ? windowDimensions.width - (!workspace ? 1185 : 1055)
        : !workspace
        ? 120
        : 200,
    [windowDimensions, workspace],
  )

  if (loading && spinnerTimeout) {
    return (
      <TasksContent noTasksAvailable={!loading && searchableTasks.length === 0}>
        <SectionHeader>
          <p style={{ position: 'absolute', bottom: 0, left: 0 }}>
            LOADING TASKS
          </p>
        </SectionHeader>
        <img
          src={placeholder}
          alt="loading placeholder"
          style={{
            width: '95%',
            height: windowDimensions.isMobile
              ? windowDimensions.width * 2
              : windowDimensions.width * 0.5,
            marginTop: windowDimensions.isMobile
              ? -windowDimensions.width * 0.6
              : -windowDimensions.width * 0.15,
            marginLeft: windowDimensions.isMobile ? 5 : 0,
          }}
        />
      </TasksContent>
    )
  }

  // add one for the header row, and one if we need the loading spinner
  const itemCount =
    tasksCount > searchableTasks.length
      ? searchableTasks.length + 2
      : searchableTasks.length + 1

  const loadNextPage = async () => {
    if (searchableTasks.length === 0) {
      return
    }

    if (!loadingNextPage && tasksCount > searchableTasks.length) {
      const nextPage = tasksPage + 1
      setTasksPage(nextPage)

      const tasks = await getTaskPage(nextPage)

      setSearchableTasks(searchableTasks.concat(tasks))
    }
  }

  return (
    <TasksContent onClick={onRequestSheetClose}>
      <FlexRow align="center" justify="flex-end">
        <TaskViewOptions
          sort={sortKey}
          handleSortChange={(key: SortOption) => setSortKey(key)}
          filter={filter}
          handleFilterChange={(f: FilterInterface) => setFilter(f)}
          showCompleted={showCompleted}
          handleShowCompletedToggle={(checked: boolean) =>
            setShowCompleted(checked)
          }
          handleResetViewOptions={handleResetViewOptions}
          contacts={contacts}
        />
      </FlexRow>

      <InfiniteLoader
        isItemLoaded={index => index < searchableTasks.length}
        itemCount={itemCount}
        loadMoreItems={loadNextPage}
        minimumBatchSize={40}
        threshold={25}
      >
        {({ onItemsRendered, ref }) => (
          <List
            ref={ref}
            height={
              !loading && searchableTasks.length === 0
                ? 50
                : windowDimensions.height - 140
            }
            width="100%"
            itemCount={searchableTasks.length + 1}
            onItemsRendered={onItemsRendered}
            overscanCount={12}
            itemSize={42}
            itemData={{
              data: [{} as SearchableTask, ...searchableTasks],
              selectedTask,
              navigateToTask,
              setTaskStatus,
              className,
              taskDrawerIsOpen,
              titleColumnWidth,
              sortKey,
              sortOrder,
              handleSort,
              totalTasks: tasksCount,
              workspace,
            }}
          >
            {TaskListRenderer}
          </List>
        )}
      </InfiniteLoader>
      {!loading &&
        searchableTasks &&
        searchableTasks.length === 0 &&
        (filter.type ? (
          <FlexRow
            style={{
              textTransform: 'none',
              textAlign: 'left',
              fontWeight: 500,
              width: 'fit-content',
              bottom: -100,
            }}
          >
            No results.
            <span
              style={{
                textDecoration: 'underline',
                cursor: 'pointer',
                paddingLeft: 3,
                paddingRight: 3,
              }}
              onClick={() => setShowTaskForm(true)}
            >
              Add a task
            </span>
            or{' '}
            <span
              style={{
                textDecoration: 'underline',
                cursor: 'pointer',
                paddingLeft: 3,
              }}
              onClick={() => setFilter({ type: undefined, value: '' })}
            >
              Clear 1 filter
            </span>
            {' .'}
          </FlexRow>
        ) : (
          <FlexRow
            style={{
              textTransform: 'none',
              textAlign: 'left',
              fontWeight: 500,
            }}
          >
            No tasks to display.
            <span
              style={{
                textDecoration: 'underline',
                cursor: 'pointer',
                margin: '0 5px',
              }}
              onClick={() => setShowTaskForm(true)}
            >
              Add your first task
            </span>
            to get started.
          </FlexRow>
        ))}
    </TasksContent>
  )
}

export default VirtualizedTaskListView
