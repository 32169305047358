import React, { useEffect } from 'react'
import { atom, useRecoilState, useRecoilValue } from 'recoil'
import * as Sentry from '@sentry/react'
import styled from 'styled-components/macro'
import { Outlet } from 'react-router-dom'
import { Drawer } from 'components/lib'
import ProfileModal from 'components/app/ContactForm/ProfileModal'
import Sidebar, {
  sidebarOpenAtom,
} from 'components/app/SidebarNav/SidebarContainer'
import ProfileSidebar from 'components/app/ProfileSidebar/ProfileSidebar'
import { OpenSidebarButton } from 'components/app/SidebarNav/SidebarHeader'

import { authenticatedUserAtom, profileModalAtom } from 'state/atoms'
import AnnouncementSheet from 'components/app/Announcements/AnnouncementSheet'
import {
  selectedAnnouncementAtom,
  showAnnouncementDrawerAtom,
} from './Announcements'

const Dashboard = styled.div`
  position: relative;
  background-color: ${props => props.theme.colors.bg0};
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  align-items: stretch;
`

const MainContent = styled.div`
  background-color: ${props => props.theme.colors.bg0};
  width: 100%;
  overflow: scroll;
  position: relative;
`

export const showProfileModalAtom = atom<boolean>({
  key: 'showProfileModal',
  default: false,
})

/**
 * Encapsulates the entire dashboard layout, including the sidebar and the main content
 */
const DashboardLayout = () => {
  const [sidebarOpen, setSidebarOpen] = useRecoilState(sidebarOpenAtom)
  const profileModal = useRecoilValue(profileModalAtom)
  const [showAnnouncementDrawer, setShowAnnouncementDrawer] = useRecoilState(
    showAnnouncementDrawerAtom,
  )
  const [selectedAnnouncement, setSelectedAnnouncement] = useRecoilState(
    selectedAnnouncementAtom,
  )

  const authenticatedUser = useRecoilValue(authenticatedUserAtom)

  useEffect(
    () =>
      Sentry.setUser({
        email: authenticatedUser.selectedContact?.email,
        id: authenticatedUser.selectedContact?.id,
      }),
    [authenticatedUser],
  )

  return (
    <Dashboard>
      <Sidebar />
      <MainContent
        onClick={() => {
          if (sidebarOpen) {
            setSidebarOpen(!sidebarOpen)
          }
        }}
      >
        <OpenSidebarButton />
        <Outlet />
      </MainContent>
      {profileModal.editProfileIsOpen && (
        <ProfileModal
          contact={profileModal.selectedContact}
          isOpen={profileModal.editProfileIsOpen}
        />
      )}
      <Drawer isOpen={showAnnouncementDrawer}>
        {selectedAnnouncement && (
          <AnnouncementSheet
            announcementId={selectedAnnouncement.id}
            onRequestClose={() => {
              // if announcement page, remove the annoucement id from the url
              if (window.location.pathname.includes('announcements')) {
                window.location.pathname.replace('/announcements', '')
              }
              setSelectedAnnouncement(undefined)
              setShowAnnouncementDrawer(false)
            }}
          />
        )}
      </Drawer>
      <Drawer isOpen={profileModal.sidebarIsOpen} width={320}>
        {profileModal.selectedContact?.id && (
          <ProfileSidebar
            // passing a blank array to tasks since we don't use it right now. can be updated later.
            tasks={[]}
          />
        )}
      </Drawer>
    </Dashboard>
  )
}

export default DashboardLayout
