import NiceModal from '@ebay/nice-modal-react'
import {
  AccessLevel,
  APIContact,
  APITask,
  APITemplate,
  Comment,
  ContactGroup,
  createContactReference,
  PropertyInfo,
  SearchableTask,
  TaskOrigin,
  TaskStatus,
  TaskStatusChangeAction,
  TaskSubscriber,
  TemplateType,
} from '@super-software-inc/foundation'
import { SubTaskReference } from '@super-software-inc/foundation/dist/types/Task'
import { createComment } from 'api/comments'
import {
  deleteAllTasksOfSchedule,
  deleteFutureTasksOfSchedule,
  getTasksOfSchedule,
} from 'api/schedules'
import {
  apiTaskToSearchableTask,
  changeTaskStatus,
  changeTaskStatusWithComment,
  updateTask,
} from 'api/tasks'
import { getCompanyTemplates } from 'api/templates'
import Editor from 'components/app/Editor/Editor'
import { EditorRef } from 'components/app/Editor/types'
import {
  FlexRow,
  GhostTextInput,
  IconButton,
  LoadingIndicator,
  MultilevelDropdown,
  MultilevelItem,
  Switch,
  TruncatedText,
  WithDropdownButton,
} from 'components/lib'
import LoadingIcon from 'components/lib/LoadingIcon'
import { Positions } from 'components/lib/MultilevelDropdown'
import { toastError, toastSuccess } from 'components/lib/Toast'
import { arrayUnion, deleteDoc, doc, updateDoc } from 'firebase/firestore'
import {
  FunctionsError,
  HttpsCallable,
  httpsCallable,
} from 'firebase/functions'
import useContactsCache from 'hooks/useContactsCache'
import useIsOverflow from 'hooks/useIsOverflow'
import {
  getSubscribersToastMessage,
  turnMentionsIntoSubscriptions,
} from 'lib/mentions'
import { uniqBy } from 'lodash'
import {
  TaskGenericError,
  TaskNoAccess,
  TaskNotFound,
} from 'pages/Secondary/TaskNotFound'
import { companyTaskCategoriesAtom, selectedTaskAtom } from 'pages/Tasks'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
  MdChevronRight,
  MdClose,
  MdDelete,
  MdLink,
  MdMoreHoriz,
  MdOutlineCloudDownload,
} from 'react-icons/md'
import { useNavigate } from 'react-router'
import { useSearchParams } from 'react-router-dom'
import { useMountedState, usePrevious } from 'react-use'
import { useFirestore, useFirestoreDocData, useFunctions } from 'reactfire'
import { useRecoilState, useRecoilValue } from 'recoil'
import {
  authenticatedUserAtom,
  searchableTasksAtom,
  tasksCountAtom,
  tempFilesAtom,
} from 'state/atoms'
import styled, { useTheme } from 'styled-components/macro'
import closeAllSubtasks from 'utils/closeAllSubtasks'
import convertFilesInDescriptionText from 'utils/convertFilesInDescriptionText'
import { associationChoicesAtom } from '../../AppRoutes'
import Composer from './Composer/Composer'
import ConfirmationModal from './ConfirmationModal'
import DownloadPdf from './PDFs/DownloadTaskPdf'
import RadioChoiceModal from './RadioChoiceModal'
import Status from './Status'
import StatusSelector, { statusOptions } from './StatusSelector'
import SubtaskEditor from './SubtaskEditor'
import SummarizeTask from './Tasks/SummarizeTask'
import TaskActionRow from './Tasks/TaskActionRow'
import TaskActivity from './Tasks/TaskActivity'
import TaskSubscriptions from './Tasks/TaskSubscriptions'

export const FileText = styled.div`
  color: ${props => props.theme.colors.text300};
  font-size: 10px;
`

export const FileHeaderText = styled.div`
  font-size: 12px;
  font-weight: bold;
  margin-top: 12px;
`

const TaskLink = styled(TruncatedText)`
  display: block;
  padding: 4px;
  color: ${props => props.theme.colors.text200};
  cursor: pointer;

  &:hover {
    color: ${props => props.theme.colors.text100};
  }

  transition: color 200ms ease;
  overflow: hidden;
`

const TaskLinkArea = styled(FlexRow)`
  position: relative;
  color: ${props => props.theme.colors.text200};
  max-width: 400px;
  overflow: hidden;
  flex-wrap: none;
`

export const Banner = styled.aside<{ visible?: boolean }>`
  background: ${props => props.theme.colors.secondaryYellow25};
  border-bottom: 1px solid ${props => props.theme.colors.secondaryYellow200};
  padding: 8px 16px;
  font-size: 14px;
  color: ${props => props.theme.colors.secondaryYellow700};
  margin: 0 -16px;
  display: ${props => (props.visible ? 'flex' : 'none')};
  align-items: center;
  justify-content: space-between;
  font-weight: 400;
`

export const BannerDismiss = styled.div`
  font-size: 16px;
  position: relative;
  top: 2px;
  cursor: pointer;
  color: ${props => props.theme.colors.secondaryYellow600};
`

const peopleNotifiedText = ({
  subscriptions,
}: {
  subscriptions: TaskSubscriber[]
}) => {
  switch (subscriptions.length) {
    case 0:
      return ''
    case 1:
      return '1 person will be notified'
    default:
      return `${subscriptions.length} people will be notified`
  }
}

interface TaskSheetProps {
  taskId: string
  onRequestClose: Function
}

interface TaskUpdate {
  field: string
  value: any
}

const TaskSheet: React.FC<TaskSheetProps> = ({ taskId, onRequestClose }) => {
  const firestore = useFirestore()
  const navigate = useNavigate()
  const functions = useFunctions()

  functions.region = 'us-east1'

  const createNextScheduledTask: HttpsCallable<{
    companyId: string
    scheduleId: string
  }> = httpsCallable(functions, 'createNextScheduledTask')

  const [searchParams] = useSearchParams()
  const workspace = searchParams.get('workspace')

  const [selectedTask, setSelectedTask] = useRecoilState(selectedTaskAtom)

  const authenticatedUser = useRecoilValue(authenticatedUserAtom)
  const associationChoices = useRecoilValue(associationChoicesAtom)
  const companyTaskCategories = useRecoilValue(companyTaskCategoriesAtom)
  const [searchableTasks, setSearchableTasks] =
    useRecoilState(searchableTasksAtom)
  const [tasksCount, setTasksCount] = useRecoilState(tasksCountAtom)

  const contactSecrets = authenticatedUser.secrets?.find(
    secret => secret.companyId === authenticatedUser.selectedCompany.id,
  )

  const [noAccess, setNoAccess] = useState<boolean>(false)
  const [notFound, setNotFound] = useState<boolean>(false)
  const [genericError, setGenericError] = useState<boolean>(false)

  const [replyTemplates, setReplyTemplates] = useState<APITemplate[]>([])

  const association = useMemo(
    () =>
      associationChoices?.find(a => a.id === selectedTask?.associationId) ||
      null,
    [associationChoices, selectedTask?.associationId],
  )

  const associationIds = useMemo(
    () => (association?.id ? [association.id] : []),
    [association?.id],
  )

  const { data: associationContacts } = useContactsCache(
    authenticatedUser.selectedCompany.id,
    associationIds,
  )

  const internalContacts = useMemo(
    () =>
      associationContacts?.filter((c: APIContact) =>
        c.propertyInfo.find(
          (p: PropertyInfo) =>
            p.associationId === selectedTask?.associationId &&
            p.groups.some(g =>
              [ContactGroup.Management, ContactGroup.Staff].includes(g),
            ),
        ),
      ) || [],
    [associationContacts, selectedTask?.associationId],
  )

  const internalSubscribers = useMemo(
    () =>
      selectedTask?.subscriptions?.filter((s: TaskSubscriber) =>
        internalContacts.find(c => c.id === s.contactId),
      ) || [],
    [selectedTask?.subscriptions, internalContacts],
  )

  /*
  const includeInternalComments = useMemo(
    () =>
      authenticatedUser.selectedContact.propertyInfo?.some(
        p =>
          (selectedAssociationIds.includes(p.associationId) &&
            p?.groups.some(
              g => g === ContactGroup.Staff || g === ContactGroup.Management,
            )) ||
          false,
      ),
    [selectedAssociationIds, authenticatedUser.selectedContact.propertyInfo],
  )
  */

  const taskRef = doc(
    firestore,
    'companies',
    authenticatedUser.selectedCompany.id,
    'companyTasks',
    taskId,
  )
  const scheduleRef = doc(
    firestore,
    'companies',
    authenticatedUser.selectedCompany.id,
    'companySchedules',
    selectedTask?.schedule?.id || ' ',
  )

  const isMounted = useMountedState()

  const getTask: HttpsCallable<
    {
      id: string
      companyId: string
    },
    APITask
  > = httpsCallable(functions, 'getTask')

  async function getTaskFromApi() {
    setNoAccess(false)
    setNotFound(false)
    setGenericError(false)

    // If there's no contact secrets yet, we can't get the task:
    if (!contactSecrets) {
      return
    }

    try {
      const { data } = await getTask({
        id: taskId,
        companyId: authenticatedUser.selectedCompany.id,
      })

      if (isMounted()) {
        // Convert the APITask to a SearchableTask:
        const searchableTask = apiTaskToSearchableTask(
          data,
          {},
          companyTaskCategories,
          authenticatedUser.selectedCompany,
          authenticatedUser.selectedContact,
          association,
        )

        setSelectedTask(searchableTask)
      }
    } catch (err) {
      if (
        (err as FunctionsError).message?.includes(
          'CONTACT_NOT_AUTHORIZED_TO_VIEW_TASK',
        )
      ) {
        setNoAccess(true)
      } else if ((err as FunctionsError).message?.includes('TASK_NOT_FOUND')) {
        setNotFound(true)
      } else {
        setGenericError(true)
      }
    }

    /*
    const task = await getTaskFromTypesense(
      taskId,
      contactSecrets,
      includeInternalComments,
    )

    if (!task) {
      setNotFound(true)
      return
    }

    if (isMounted()) {
      setSelectedTask(task)
    }
    */
  }

  const { data: schedule } = useFirestoreDocData(scheduleRef, {
    idField: 'id',
  })
  const editorRef = useRef<EditorRef>(null)
  const [tempDescription, setTempDescription] = useState('')
  const [tempTitle, setTempTitle] = useState('')
  const [isDeleted, setIsDeleted] = useState(false)
  const [downloadPdfIsOpen, setDownloadPdfIsOpen] = useState(false)
  const [updatingInProgress, setUpdatingInProgress] = useState(false)

  const propertyInfo = useMemo(
    () =>
      authenticatedUser.selectedContact?.propertyInfo.find(
        (p: PropertyInfo) => p.associationId === selectedTask?.associationId,
      ),

    [authenticatedUser.selectedContact, selectedTask?.associationId],
  )

  const copyTaskLinkToClipboard = () => {
    window.navigator.clipboard.writeText(
      `${window.location.origin}/tasks/${taskRef.id}`,
    )
  }

  const theme = useTheme()

  // TODO: We have both `prevTaskId` and `prevSelectedTaskId` in this file. Can
  // we consolidate them?
  const prevSelectedTaskId = usePrevious(selectedTask?.id)
  const prevAssociationId = usePrevious(selectedTask?.associationId)

  const [tempFiles, setTempFiles] = useRecoilState(tempFilesAtom)
  const [commentIsInternal, setCommentIsInternal] = useState(false)

  const [isSubmitting, setIsSubmitting] = useState(false)
  const [composerValues, setComposerValues] =
    useState<Omit<Comment, 'author'>>()

  const [commentAndCloseAction, setCommentAndCloseAction] = useState<
    'comment' | 'commentAndChangeStatus' | 'commentAndClose'
  >()
  const [statusToChange, setStatusToChange] = useState<TaskStatus>(
    selectedTask?.status || TaskStatus.OPEN,
  )
  const commentAndCloseCallbacks = useMemo(
    () => ({
      comment: async () => {
        if (!composerValues || !selectedTask) return

        const { mentions, subscriptions: mentionSubscriptions } =
          await turnMentionsIntoSubscriptions(
            composerValues.text,
            selectedTask.associationId,
          )
        try {
          const updatedTask = await createComment({
            companyId: authenticatedUser.selectedCompany.id,
            contact: authenticatedUser.selectedContact,
            task: selectedTask,
            values: { ...composerValues, internal: commentIsInternal },
            mentionSubscriptions,
          })

          const searchableTask = apiTaskToSearchableTask(
            updatedTask,
            {},
            companyTaskCategories,
            authenticatedUser.selectedCompany,
            authenticatedUser.selectedContact,
            association,
          )

          setSelectedTask(searchableTask)

          if (mentionSubscriptions.length === 0) {
            // dont show toast
          } else if (mentions && mentions.length) {
            const subscribedMentions = mentions.filter(
              mention =>
                mention.attrs.type === 'group' ||
                mentionSubscriptions.find(
                  sub => sub.contactId === mention.attrs.id,
                ),
            )
            toastSuccess(getSubscribersToastMessage(subscribedMentions))
          }
        } catch (e) {
          // error creating comment
        }
      },
      commentAndChangeStatus: async (newStatus: TaskStatus) => {
        if (!composerValues || !selectedTask) return

        const updatedTask = await changeTaskStatusWithComment({
          companyId: authenticatedUser.selectedCompany.id,
          contact: authenticatedUser.selectedContact,
          task: selectedTask,
          newStatus,
          values: { ...composerValues, internal: commentIsInternal },
          withMentions: true,
          action: TaskStatusChangeAction.COMMENT,
        })

        const searchableTask = apiTaskToSearchableTask(
          updatedTask,
          {},
          companyTaskCategories,
          authenticatedUser.selectedCompany,
          authenticatedUser.selectedContact,
          association,
        )

        setSelectedTask(searchableTask)
      },
      commentAndClose: async () => {
        if (!composerValues || !selectedTask) return

        const contactReference = createContactReference(
          authenticatedUser.selectedContact,
          selectedTask.associationId || undefined,
        )

        const updatedTask = await changeTaskStatusWithComment({
          companyId: authenticatedUser.selectedCompany.id,
          contact: authenticatedUser.selectedContact,
          task: selectedTask,
          newStatus:
            selectedTask.status === TaskStatus.CLOSED
              ? TaskStatus.OPEN
              : TaskStatus.CLOSED,
          values: { ...composerValues, internal: commentIsInternal },
          withMentions: true,
          action: TaskStatusChangeAction.NOTIFY,
        })

        const searchableTask = apiTaskToSearchableTask(
          updatedTask,
          {},
          companyTaskCategories,
          authenticatedUser.selectedCompany,
          authenticatedUser.selectedContact,
          association,
        )

        setSelectedTask(searchableTask)

        await closeAllSubtasks(
          authenticatedUser.selectedCompany.id,
          selectedTask.id,
          contactReference,
        )
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [authenticatedUser.selectedContact, selectedTask, composerValues],
  )

  const userContactReference = createContactReference(
    authenticatedUser.selectedContact,
    selectedTask?.associationId || undefined,
  )

  const onSubscriberChange = async (updatedSubscribers: TaskSubscriber[]) => {
    await updateDoc(taskRef, {
      subscriptions: updatedSubscribers,
      modifiedBy: userContactReference,
    })
      .then(() => {
        setSelectedTask({
          ...(selectedTask as SearchableTask),
          subscriptions: updatedSubscribers as TaskSubscriber[],
        })
        toastSuccess('Subscribers updated successfully.')
      })
      .catch(() => {
        toastError('Error updating subscribers.')
      })
  }

  const getDropdownOptions = useCallback(
    (submitForm: Function) =>
      statusOptions
        .filter(
          option => option.value !== selectedTask?.status && !option.notify,
        )
        .map((option, idx) => ({
          label: (
            <FlexRow align="center">
              <Status status={option.value} style={{ marginRight: 8 }} />
              Send and
              {![TaskStatus.CLOSED, TaskStatus.CANCELLED].includes(option.value)
                ? ' mark'
                : ''}
              &nbsp;<strong>{option.verb}</strong>
            </FlexRow>
          ),
          id: idx,
          onClick: () => {
            submitForm()
            setStatusToChange(option.value)

            if (option.value === TaskStatus.CLOSED) {
              setCommentAndCloseAction('commentAndClose')
              commentAndCloseCallbacks.commentAndClose()
            } else {
              setCommentAndCloseAction('commentAndChangeStatus')
              commentAndCloseCallbacks.commentAndChangeStatus(option.value)
            }
          },
        })),
    [selectedTask?.status, commentAndCloseCallbacks],
  )

  const getCommentAndCloseButton = useCallback(
    (isDisabled: boolean, submitForm: Function) => (
      <WithDropdownButton
        isDisabled={isDisabled}
        primaryLabel={`Send${
          selectedTask ? ` & keep ${selectedTask.status.replace('-', ' ')}` : ''
        }`}
        primaryOnClick={() => {
          setCommentAndCloseAction('comment')
          submitForm()
        }}
        dropdownOptions={getDropdownOptions(submitForm)}
        position={Positions.TopLeft}
      />
    ),
    [selectedTask, getDropdownOptions],
  )

  const canClose = useMemo<boolean>(
    () =>
      propertyInfo?.acl?.tasks.edit ||
      selectedTask?.createdBy?.contactId ===
        authenticatedUser.selectedContact?.id ||
      selectedTask?.assignee?.contactId ===
        authenticatedUser.selectedContact?.id,
    [
      selectedTask,
      authenticatedUser.selectedContact,
      propertyInfo?.acl?.tasks.edit,
    ],
  )

  useEffect(() => {
    if (!(isSubmitting && composerValues)) return

    if (!canClose) {
      // user is not allowed to close/reopen tasks, hence can only comment
      commentAndCloseCallbacks.comment()
    } else if (commentAndCloseAction) {
      commentAndCloseCallbacks[commentAndCloseAction](statusToChange)
    }
    setIsSubmitting(false)
    setCommentAndCloseAction(undefined)
    setComposerValues(undefined)
  }, [
    composerValues,
    commentAndCloseAction,
    statusToChange,
    isSubmitting,
    commentAndCloseCallbacks,
    canClose,
  ])

  useEffect(() => {
    if (!selectedTask) {
      return
    }

    if (prevSelectedTaskId !== selectedTask.id) {
      setTempDescription(selectedTask.description)
      setTempTitle(selectedTask.title)
      setNoAccess(false)
      setNotFound(false)
      setGenericError(false)
      const getTemplates = async () => {
        const templates = await getCompanyTemplates(
          authenticatedUser.selectedCompany.id,
          [TemplateType.COMMENT],
        )
        setReplyTemplates(templates)
      }
      // make sure user is admin to use templates
      if (propertyInfo?.accessLevel === AccessLevel.AdminAccess) {
        getTemplates()
      } else {
        setReplyTemplates([])
      }
      if (editorRef.current) {
        editorRef.current.value = selectedTask.description
      }
    } else if (prevAssociationId !== selectedTask.associationId) {
      if (editorRef.current) {
        editorRef.current.value = selectedTask.description
      }
    }
  }, [
    selectedTask,
    prevSelectedTaskId,
    prevAssociationId,
    authenticatedUser.selectedCompany.id,
    propertyInfo?.accessLevel,
  ])

  useEffect(() => {
    setIsDeleted(false)
  }, [taskRef.id])

  const prevTaskId = usePrevious(taskId)

  // Get the task upon heartbeat, or if the task ID changes:
  useEffect(() => {
    async function onChange() {
      try {
        const { data } = await getTask({
          id: taskId,
          companyId: authenticatedUser.selectedCompany.id,
        })

        if (isMounted()) {
          // Convert the APITask to a SearchableTask:
          const latestTask = apiTaskToSearchableTask(
            data,
            {},
            companyTaskCategories,
            authenticatedUser.selectedCompany,
            authenticatedUser.selectedContact,
            association,
          )

          if (
            !latestTask.read?.includes(authenticatedUser.selectedContact.id)
          ) {
            // Set the task data locally assuming the task is read:
            setSelectedTask({
              ...latestTask,
              read: [...latestTask.read, authenticatedUser.selectedContact.id],
            })

            // Set the task as read:
            await updateDoc(taskRef, {
              read: arrayUnion(authenticatedUser.selectedContact.id),
            })
          } else {
            setSelectedTask(latestTask)
          }
        }
      } catch (err) {
        if (
          (err as FunctionsError).message?.includes(
            'CONTACT_NOT_AUTHORIZED_TO_VIEW_TASK',
          )
        ) {
          setNoAccess(true)
        } else if (
          (err as FunctionsError).message?.includes('TASK_NOT_FOUND')
        ) {
          setNotFound(true)
        } else {
          setGenericError(true)
        }
      }

      /*
      const latestTask = await getTaskFromTypesense(
        taskId,
        contactSecrets,
        includeInternalComments,
      )

      if (!latestTask) {
        setNotFound(true)
        return
      }

      if (!latestTask.read?.includes(authenticatedUser.selectedContact.id)) {
        // Set the task data locally assuming the task is read:
        setSelectedTask({
          ...latestTask,
          read: [...latestTask.read, authenticatedUser.selectedContact.id],
        })

        // Set the task as read:
        await updateDoc(taskRef, {
          read: arrayUnion(authenticatedUser.selectedContact.id),
        })
      } else {
        setSelectedTask(latestTask)
      }
      */
    }

    if (!contactSecrets) {
      return
    }

    if (prevTaskId !== taskId || !selectedTask) {
      onChange()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contactSecrets, selectedTask?.id, prevTaskId, taskId])

  const hasSmsSubscribers = useMemo(
    () =>
      (selectedTask?.subscriptions || []).some((s: TaskSubscriber) =>
        s.preferences?.includes('phone'),
      ),
    [selectedTask?.subscriptions],
  )

  const hasLandlineOnlyCreator = useMemo(
    // TODO - we don't need to update this when the subscriptions are updated. Should only be on first render.
    () =>
      selectedTask?.origin === TaskOrigin.VOICE_LANDLINE &&
      (!selectedTask.subscriptions ||
        selectedTask?.subscriptions.find(
          (s: TaskSubscriber) =>
            s.contactId === selectedTask.createdBy?.contactId,
        ) === undefined),

    [
      selectedTask?.origin,
      selectedTask?.subscriptions,
      selectedTask?.createdBy?.contactId,
    ],
  )

  const canDelete = useMemo(() => {
    // Allow for no property tasks to be deleted by a contact with delete
    // permissions in the company's properties.
    if (
      selectedTask?.associationId === null &&
      authenticatedUser.selectedContact.propertyInfo.some(
        (p: PropertyInfo) => p.acl?.tasks.delete,
      )
    ) {
      return true
    }

    // Allow for tasks to be deleted by a contact with delete permissions in
    // the task's property.
    if (propertyInfo?.acl?.tasks.delete) {
      return true
    }

    // Allow for tasks to be deleted by the creator.
    if (
      selectedTask?.createdBy?.contactId ===
      authenticatedUser.selectedContact?.id
    ) {
      return true
    }

    // Allow for tasks to be deleted by the assignee.
    if (
      selectedTask?.assignee?.contactId ===
      authenticatedUser.selectedContact?.id
    ) {
      return true
    }

    return false
  }, [
    selectedTask?.associationId,
    selectedTask?.createdBy?.contactId,
    selectedTask?.assignee?.contactId,
    authenticatedUser.selectedContact.id,
    authenticatedUser.selectedContact.propertyInfo,
    propertyInfo,
  ])

  const finishTaskDeletion = async () => {
    if (!selectedTask) return

    setIsDeleted(true)
    await deleteDoc(taskRef)

    const taskIndex = searchableTasks.findIndex(t => t.id === selectedTask.id)

    if (taskIndex > -1) {
      setSearchableTasks([
        ...searchableTasks.slice(0, taskIndex),
        ...searchableTasks.slice(taskIndex + 1),
      ])
      setTasksCount(tasksCount - 1)
    }

    toastSuccess(
      <div>
        Task deleted: &ldquo;
        {selectedTask.title.length > 20
          ? `${selectedTask.title.slice(0, 20)}...`
          : selectedTask.title}
        &ldquo;
      </div>,
    )

    onRequestClose()
  }

  const downloadPdf = () => {
    setDownloadPdfIsOpen(true)
  }

  const [isSmsBannerDismissed, setIsSmsBannerDismissed] = useState<boolean>(
    localStorage.getItem(`dismissed-sms-banner-${taskId}`) === 'true',
  )

  const [isLandlineBannerDismissed, setIsLandlineBannerDismissed] =
    useState<boolean>(
      localStorage.getItem(`dismissed-landline-banner-${taskId}`) === 'true',
    )

  // adjust the shadow above text input if the contents scroll
  const [isOverflowing, setIsOverflowing] = useState<boolean>(false)
  const sheetRef = React.useRef(null)

  useIsOverflow(sheetRef, (isOverflowFromCallback: boolean) => {
    if (isOverflowFromCallback !== isOverflowing) {
      setIsOverflowing(isOverflowFromCallback)
    }
  })

  const [updatesToApply, setUpdatesToApply] = useState<TaskUpdate[]>([])

  // Process updates queue upon changes to updatesToApply:
  useEffect(() => {
    if (!selectedTask) {
      return undefined // Can't do any updates if there is no task.
    }

    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      e.preventDefault()
      e.returnValue = ''
    }

    const processUpdatesQueue = async (update: TaskUpdate) => {
      setUpdatingInProgress(true)
      window.addEventListener('beforeunload', handleBeforeUnload)

      const updatedTask = await updateTask(
        authenticatedUser.selectedCompany.id,
        selectedTask.associationId,
        selectedTask.id,
        {
          [update.field]: update.value,
        },
      )

      // Set the selected task locally too, in case of any ids of things (e.g. schedules)
      // that we ought to append to the task object for consistent subsequent updates.
      setSelectedTask(
        apiTaskToSearchableTask(
          updatedTask,
          {},
          companyTaskCategories,
          authenticatedUser.selectedCompany,
          authenticatedUser.selectedContact,
          association,
        ),
      )

      setUpdatingInProgress(false)
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }

    if (updatesToApply.length && !updatingInProgress) {
      processUpdatesQueue(updatesToApply[0])

      const remainingUpdates = updatesToApply.slice(1)
      setUpdatesToApply(remainingUpdates)
    }

    if (!updatesToApply.length && updatingInProgress) {
      setUpdatingInProgress(false)
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatesToApply])

  useEffect(() => {
    if (selectedTask?.associationId === null) {
      setCommentIsInternal(false)
    }
  }, [selectedTask?.associationId])

  if (noAccess) {
    return <TaskNoAccess onRequestClose={() => onRequestClose()} />
  }

  if (notFound) {
    return <TaskNotFound onRequestClose={() => onRequestClose()} />
  }

  if (genericError) {
    return <TaskGenericError onRequestClose={() => onRequestClose()} />
  }

  // check the loading status
  if (isDeleted || !selectedTask) {
    return (
      <div
        style={{
          display: 'flex',
          height: '100%',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <LoadingIndicator />
      </div>
    )
  }

  return (
    <div
      style={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
      }}
    >
      {/* top row */}
      <FlexRow>
        <div style={{ alignSelf: 'center' }}>
          <p style={{ color: theme.colors.text250 }}>
            {selectedTask.shortCodeId}
          </p>
        </div>
        {downloadPdfIsOpen && association && (
          <DownloadPdf
            task={selectedTask}
            companyId={authenticatedUser.selectedCompany.id}
            association={association}
            onClose={() => setDownloadPdfIsOpen(false)}
          />
        )}
        <div style={{ marginLeft: 'auto' }} />
        <MultilevelDropdown
          title={
            <IconButton style={{ marginTop: -7 }}>
              <MdMoreHoriz />
            </IconButton>
          }
        >
          <MultilevelItem onClick={() => copyTaskLinkToClipboard()}>
            <FlexRow align="center">
              <MdLink style={{ marginRight: 8, fontSize: 16 }} /> Copy link
            </FlexRow>
          </MultilevelItem>
          <MultilevelItem onClick={() => downloadPdf()}>
            <FlexRow align="center">
              <MdOutlineCloudDownload
                style={{ marginRight: 8, fontSize: 16 }}
              />
              Export PDF
            </FlexRow>
          </MultilevelItem>
          {canDelete && (
            <MultilevelItem
              destructive
              onClick={async () => {
                if (taskRef) {
                  if (selectedTask.schedule) {
                    NiceModal.show(RadioChoiceModal, {
                      title: 'Delete recurring task',
                      choices: [
                        { id: 'this', label: 'This task' },
                        { id: 'future', label: 'This and following tasks' },
                        { id: 'all', label: 'All tasks' },
                      ],
                    }).then(async (result: any) => {
                      if (result) {
                        // Handle if the choice is for this task or the whole series.
                        if (result.id === 'future' || result.id === 'all') {
                          // Delete the schedule as it is no longer needed..
                          deleteDoc(scheduleRef)

                          // If the choice is future, delete all instances of future tasks of this schedule.
                          if (result.id === 'future') {
                            deleteFutureTasksOfSchedule(
                              authenticatedUser.selectedCompany.id,
                              scheduleRef.id,
                            )
                          }
                          // If the choice is "all", delete all tasks of this schedule.
                          if (result.id === 'all') {
                            setIsDeleted(true)
                            deleteAllTasksOfSchedule(
                              authenticatedUser.selectedCompany.id,
                              scheduleRef.id,
                            )
                          }
                        } else {
                          // If this is the only task of the schedule, create
                          // the next copy before deleting.
                          const tasksOfSchedule = await getTasksOfSchedule(
                            scheduleRef.id,
                            authenticatedUser.selectedCompany.id,
                          )

                          if (tasksOfSchedule.length === 1) {
                            // First, close the task so the next task creation
                            // is not skipped in the step below.
                            await updateTask(
                              authenticatedUser.selectedCompany.id,
                              selectedTask.associationId,
                              selectedTask.id,
                              {
                                status: TaskStatus.CLOSED,
                              },
                            )

                            await createNextScheduledTask({
                              companyId: authenticatedUser.selectedCompany.id,
                              scheduleId: scheduleRef.id,
                            })
                          }

                          // Create a hole in the schedule so that this task date is skipped.
                          updateDoc(scheduleRef, {
                            skipDates: arrayUnion(selectedTask.dueDate),
                          })
                        }

                        getTaskFromApi()
                        // In all three cases, delete this task.
                        return finishTaskDeletion()
                      }

                      return null
                    })
                  } else {
                    NiceModal.show(ConfirmationModal, {
                      message:
                        'Are you sure you want to permanently delete this task?',
                    }).then((result: any) => {
                      if (result === 'confirm') {
                        return finishTaskDeletion()
                      }

                      return null
                    })
                  }
                }
              }}
            >
              <FlexRow align="center">
                <MdDelete style={{ marginRight: 8, fontSize: 16 }} /> Delete
              </FlexRow>
            </MultilevelItem>
          )}
        </MultilevelDropdown>
        <IconButton
          disabled={updatingInProgress}
          onClick={() => onRequestClose()}
        >
          {updatingInProgress ? <LoadingIcon /> : <MdClose />}
        </IconButton>
      </FlexRow>
      <FlexRow>
        <StatusSelector
          className="px-2 py-1"
          value={selectedTask.status}
          task={selectedTask}
          onChange={async ({ task, newStatus, action }) => {
            async function uponChange() {
              // Patch the task locally immediately:
              if (selectedTask) {
                setSelectedTask({
                  ...selectedTask,
                  status: newStatus,
                })
              }

              const contactReference = createContactReference(
                authenticatedUser.selectedContact,
                task.associationId || undefined,
              )

              await changeTaskStatus({
                companyId: authenticatedUser.selectedCompany.id,
                contact: authenticatedUser.selectedContact,
                task,
                newStatus,
                action,
              })

              if (newStatus === TaskStatus.CLOSED) {
                await closeAllSubtasks(
                  authenticatedUser.selectedCompany.id,
                  task.id,
                  contactReference,
                )
              }
            }

            uponChange()
          }}
          style={{ marginTop: 1 }}
        />
        <div style={{ width: '100%' }}>
          {/* Task title */}
          <FlexRow align="center">
            <GhostTextInput
              onBlur={async () => {
                // Update task locally immediately:
                setSelectedTask({
                  ...selectedTask,
                  title: tempTitle,
                })
                await updateTask(
                  authenticatedUser.selectedCompany.id,
                  selectedTask.associationId,
                  selectedTask.id,
                  {
                    title: tempTitle,
                  },
                )
              }}
              value={tempTitle}
              onChange={evt => {
                const text = evt.target.value
                setTempTitle(text)
              }}
              style={{ fontSize: '20px', fontWeight: 'bold' }}
            />
          </FlexRow>
        </div>
      </FlexRow>

      {selectedTask.parentTask && (
        <TaskLinkArea align="center" style={{ marginBottom: 16 }}>
          <TaskLink
            as="a"
            onClick={() => {
              navigate(
                // @ts-ignore
                `/tasks/${selectedTask.parentTask}${
                  workspace ? `?workspace=${workspace}` : ''
                }`,
              )
            }}
          >
            {selectedTask.parentTaskTitle}
          </TaskLink>

          <MdChevronRight style={{ flexShrink: 0 }} />

          <TaskLink as="a">{selectedTask.title}</TaskLink>
        </TaskLinkArea>
      )}

      <SummarizeTask selectedTask={selectedTask} />

      <div
        ref={sheetRef}
        style={{
          // To avoid drop shadow cut-off issue.
          paddingLeft: 16,
          marginLeft: -16,
          overflowY: 'auto',
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <FlexRow>
          <div style={{ width: '100%' }}>
            {/* Task description */}
            <div
              onBlur={async () => {
                // Don't incur changes if nothing has changed.
                if (tempDescription === selectedTask.description) {
                  return
                }

                const newSubscriptions = []
                if (tempDescription && tempDescription.length) {
                  const { mentions, subscriptions: mentionSubscriptions } =
                    await turnMentionsIntoSubscriptions(
                      tempDescription,
                      selectedTask.associationId,
                    )
                  if (mentionSubscriptions && mentionSubscriptions.length) {
                    newSubscriptions.push(...mentionSubscriptions)
                    if (mentions && mentions.length) {
                      const toastSubscribersMessage =
                        getSubscribersToastMessage(mentions)

                      toastSuccess(toastSubscribersMessage)
                    }
                  }
                }

                // upload any temporary files and replace in the description
                const description = await convertFilesInDescriptionText(
                  tempDescription,
                  tempFiles,
                  authenticatedUser.selectedCompany.id,
                  association,
                  createContactReference(
                    authenticatedUser.selectedContact,
                    selectedTask.associationId || undefined,
                  ),
                )

                // Remove any empty checkboxes at the end of the description
                description?.trim().replace(/(- \[\s\])$/, '')

                // Update the task locally immediately:
                setSelectedTask({
                  ...selectedTask,
                  description,
                  subscriptions: uniqBy(
                    [
                      ...(selectedTask.subscriptions || []),
                      ...newSubscriptions,
                    ],
                    'contactId',
                  ),
                })

                await updateTask(
                  authenticatedUser.selectedCompany.id,
                  selectedTask.associationId,
                  selectedTask.id,
                  {
                    description,
                    subscriptions: uniqBy(
                      [
                        ...(selectedTask.subscriptions || []),
                        ...newSubscriptions,
                      ],
                      'contactId',
                    ),
                  },
                )

                setTempFiles([])
              }}
            >
              <Editor
                ref={editorRef}
                placeholder="Add description"
                associationId={selectedTask.associationId || undefined}
                contacts={associationContacts}
                onChange={val => {
                  setTempDescription(val)
                }}
                condenseContent={100}
                referenceId={selectedTask.id}
              />
            </div>
          </div>
        </FlexRow>

        <TaskActionRow
          task={selectedTask}
          schedule={schedule}
          onFieldChange={async (field: string, value: any) => {
            // Change field locally immediately:
            setSelectedTask({
              ...selectedTask,
              [field]: value,
            })

            // Add update to queue:
            setUpdatesToApply([...updatesToApply, { field, value }])
          }}
          style={{
            marginBottom: 32,
            flexWrap: 'wrap',
          }}
        />

        {/* Subtasks */}
        {selectedTask && !selectedTask.parentTask && association && (
          <div style={{ marginBottom: 24 }}>
            <SubtaskEditor
              association={association}
              parentTask={selectedTask}
              onSubtaskClicked={(subtask: SubTaskReference) => {
                navigate(
                  `/tasks/${subtask.id}${
                    workspace ? `?workspace=${workspace}` : ''
                  }`,
                )
              }}
              onSubtaskAdded={(subTask: SubTaskReference) => {
                if (!subTask) {
                  return
                }

                // Add the subtask to the selectedTask object:
                setSelectedTask({
                  ...selectedTask,
                  subTasks: [...selectedTask.subTasks, subTask],
                })
              }}
              onSubtaskStatusChanged={(
                task: APITask,
                newStatus: TaskStatus,
              ) => {
                // Update the status of the subtask in the selectedTask object:
                const modifiedSubtasks = selectedTask.subTasks.map(
                  (subtask: SubTaskReference) => {
                    if (subtask.id === task.id) {
                      return {
                        ...subtask,
                        status: newStatus,
                      }
                    }
                    return subtask
                  },
                )

                setSelectedTask({
                  ...selectedTask,
                  subTasks: modifiedSubtasks,
                })
              }}
            />
          </div>
        )}
        <FlexRow style={{ marginBottom: 8 }}>
          <div>
            <h3>Activity</h3>
          </div>
          <TaskSubscriptions
            onChange={onSubscriberChange}
            associationId={selectedTask.associationId}
            subscribers={selectedTask.subscriptions || []}
            contacts={associationContacts}
            isTaskSheet
          />
        </FlexRow>

        <TaskActivity
          selectedTask={selectedTask}
          taskRef={taskRef}
          comments={selectedTask.comments || []}
          companyId={authenticatedUser.selectedCompany.id}
          associationId={selectedTask.associationId}
        />
      </div>
      <div
        style={{
          height: 1,
          width: '100%',
          backgroundColor: '#e0e4e8',
          boxShadow: isOverflowing
            ? `0px -2px 5px rgba(138,148,166, .3)`
            : 'none',
        }}
      />
      {/* Comment form */}
      <Banner visible={hasLandlineOnlyCreator && !isLandlineBannerDismissed}>
        <div>Landline was not subscribed and cannot receive notifications.</div>
        <BannerDismiss
          onClick={() => {
            setIsLandlineBannerDismissed(true)
            localStorage.setItem(`dismissed-landline-banner-${taskId}`, 'true')
          }}
        >
          <MdClose />
        </BannerDismiss>
      </Banner>
      <Banner visible={hasSmsSubscribers && !isSmsBannerDismissed}>
        <div>
          SMS subscribers will only receive plain text updates in SMS replies.
        </div>
        <BannerDismiss
          onClick={() => {
            setIsSmsBannerDismissed(true)
            localStorage.setItem(`dismissed-sms-banner-${taskId}`, 'true')
          }}
        >
          <MdClose />
        </BannerDismiss>
      </Banner>
      {
        // only manangement and staff can post internal comments
        [ContactGroup.Management, ContactGroup.Staff].some(group =>
          propertyInfo?.groups?.includes(group),
        ) && (
          <FlexRow
            style={{
              padding: 10,
            }}
          >
            <Switch
              checked={commentIsInternal}
              onChange={checked => {
                setCommentIsInternal(checked)
              }}
            />
            <h5 className="text-sky-950 text-sm font-medium ml-2">Internal</h5>
          </FlexRow>
        )
      }
      <Composer
        parentItemId={selectedTask.id}
        actionLabel="Comment"
        placeholder={
          commentIsInternal
            ? `Comment to ${
                internalSubscribers.length
              } management and staff subscriber${
                internalSubscribers.length === 1 ? '' : 's'
              }`
            : `Reply to ${selectedTask.subscriptions?.length} subscriber${
                selectedTask.subscriptions?.length === 1 ? '' : 's'
              }`
        }
        statusText={
          selectedTask.subscriptions &&
          peopleNotifiedText({
            subscriptions: commentIsInternal
              ? internalSubscribers
              : selectedTask.subscriptions,
          })
        }
        editorProps={{
          associationId: selectedTask.associationId,
          contacts: commentIsInternal ? internalContacts : associationContacts,
          templates: replyTemplates,
          selectedTask,
          style: {
            maxHeight: '40vh',
          },
        }}
        enablePolling
        isInternal={commentIsInternal}
        requiresStepIn
        showUserAvatar
        onSubmit={(values: any) => {
          setIsSubmitting(true)
          setComposerValues(values)
        }}
        association={association}
        submitComponent={canClose ? getCommentAndCloseButton : undefined}
      />
    </div>
  )
}

export default TaskSheet
